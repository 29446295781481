<template>
  <div class="wrapper-chatbox">
    <BaseHeader
      :llm_name="llm_name"
      :currentTab="currentTab"
      @change-model="updateLLM"
      @change-tab="updateTab"
    />
    <ChatPage v-show="this.currentTab === 'chat'" :llm_name="llm_name" />
    <DocPage v-show="this.currentTab === 'docs'" />
    <div class="footer">
      <p>Chatbot tư vấn tuyển sinh THPT chuyên Bến Tre</p>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue'
import ChatPage from './views/ChatPage.vue';
import DocPage from './views/DocPage.vue';

export default {
  name: 'App',
  components: {
    BaseHeader,
    ChatPage,
    DocPage,
  },
  data() {
    return {
      llm_name: "ollama",
      currentTab: "chat",
    }
  },
  methods: {
    updateLLM(newValue) {
      this.llm_name = newValue;
    },
    updateTab(newValue) {
      this.currentTab = newValue;
    }
  }
};
</script>
