<template>
  <div class="header">
    <div class="header__wrap">
      <div class="logo">
        <!-- <img src="@/assets/images/logo.png"> -->
      </div>
      <div class="top-menu">
        <span v-for="(tab, idx) in tabs"
          :key="'tab-'+idx"
          :class="{'active': currentTab === tab}"
          @click="changeTab(tab)"
        >
          {{ tab.toUpperCase() }}
        </span>

        <label v-show="currentTab==='chat'" class="select-wrapper">
          <select v-model="selectedValue" @change="changeModel()">
            <option value="ollama">Ollama</option>
            <option value="gemini">Gemini</option>
          </select>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    llm_name: { type: String, required: true },
    currentTab: { type: String, required: true },
  },
  emits: ["change-model", "change-tab"],
  data() {
    return {
      selectedValue: this.llm_name,
      tabs: ["chat", "docs"],
    }
  },
  methods: {
    changeModel() {
      this.$emit("change-model", this.selectedValue);
    },
    changeTab(newTab) {
      this.$emit("change-tab", newTab);
    }
  }
}
</script>

<style scoped>
.select-wrapper select {
  background-color: #1f1c3b;
  color: #fff;
  padding: 4px;
  font-size: 1.4em;
  -webkit-appearance: button;
  appearance: button;
  outline: none;
}
</style>