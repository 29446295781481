import axios from "axios";

const API_HOST = process.env.NODE_ENV === "production"
  ? "https://enormously-huge-stallion.ngrok-free.app"
  : "http://localhost:8000";


export function checkServer() {
  return axios.get(API_HOST);
}

export function fetchAnswer(question, llm_name) {
  return axios.post(`${API_HOST}/api/tasks/ask/`, {
    question: question,
    llm_name: llm_name,
  });
}

export function getDocLink(fileName) {
  return `${API_HOST}/api/tasks/doc/${fileName}`;
}

export function fetchAllDocs() {
  return axios.get(`${API_HOST}/api/tasks/docs/`)
}