<template>
  <div class="chatbox__content" ref="chatContent">
    <!-- chat content -->
    <div v-for="(item, index) in histories" :key="'msg-'+index" class="msg__item">
      <div class="box__common text" :class="item.system ? 'box__system' : 'box__self'">
        <div v-if="item.system" class="system__avatar"></div>
        <div class="system__msg">{{ item.msg }}</div>

        <!-- reference documents -->
        <div v-if="item.references?.length">
          <br />
          <p>Nguồn tài liệu:</p>
          <ul class="docs-link">
            <li v-for="(el, idx) in item.references" :key="'doc-ref-'+idx">
              <a :href="getDocLink(el)" target="_blank">{{ el }}</a>
            </li>
          </ul>
        </div>

        <div :class="item.system ? 'system__datetime' : 'self__datetime'">{{ item.time }}</div>
      </div>
    </div>
    <!-- dot dot dot animation -->
    <div v-show="loading" class="msg__item">
      <div class="box__system waiting-box">
        <div class="system__avatar"></div>
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDocLink } from "/src/apis/general.js";

export default {
  props: {
    histories: { type: Array, required: true },
    loading: { type: Boolean, required: true },
  },
  watch: {
    "histories.length": function () {
      this.$nextTick(() => {
        const element = this.$refs.chatContent;
        element.scrollTo({
          behavior: "smooth",
          top: element.scrollHeight,
        });
      });
    }
  },
  methods: {
    getDocLink: getDocLink,
  }
}
</script>
