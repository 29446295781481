<template>
  <div class="body">
    <div class="doc-body">
      <div v-if="docs.length">
        <p>Bạn có thể tìm thêm thông tin bằng cách đọc các văn bản tài liệu sau:</p>
        <ul class="docs-link">
          <li v-for="(el, idx) in docs" :key="'doc-ref-'+idx">
            <a :href="getDocLink(el)" target="_blank">{{ el }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAllDocs, getDocLink } from "@/apis/general.js";

export default {
  data() {
    return {
      docs: [],
    }
  },
  created() {
    this.initialFetch();
  },
  methods: {
    getDocLink: getDocLink,
    async initialFetch() {
      const res = await fetchAllDocs();
      this.docs = res.data?.all_docs || [];
    },
  }
}
</script>

<style scoped>
.doc-body {
  height: calc(100vh - 102px);
  background-color: rgba(33, 33, 33, 0.5);
  overflow-x: auto;
}
.doc-body p {
  color: #fff;
  padding: 1rem;
  margin: 0;
}
ul {
  margin: 0;
}
ul li {
  margin: 0;
  padding: 1rem;
  list-style-type: none;
}
</style>