<template>
  <div class="wrapper-chatbox">
    <div class="body">
      <div class="chatbox">
        <ChatContent :histories="histories" :loading="loading" />
        <ChatSender @submit-question="submitQuestion" :online="online" />
      </div>
    </div>
  </div>
</template>

<script>
import { checkServer, fetchAnswer } from "/src/apis/general.js";

import ChatContent from "@/components/chat/ChatContent.vue";
import ChatSender from "@/components/chat/ChatSender.vue";

function nowTime() {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
}

export default {
  components: {
    ChatContent,
    ChatSender,
  },
  props: {
    llm_name: { type: String, required: true },
  },
  data() {
    return {
      online: false,
      loading: false,
      histories: [],
    }
  },
  mounted() {
    this.checkOnline();
  },
  methods: {
    submitQuestion(question) {
      this.histories.push({
        system: false,
        msg: question,
        time: nowTime(),
      });
      this.askChatbot(question);
    },
    async askChatbot(question) {
      this.loading = true;
      const response = await fetchAnswer(question, this.llm_name);
      this.loading = false

      const answer = response.data.msg;
      const references = response.data.references;
      this.histories.push({
        system: true,
        msg: answer,
        references: references,
        time: nowTime(),
      });
    },
    async checkOnline() {
      let initialMsg = "Xin chào, tôi sẽ trả lời cho bạn các câu hỏi về trường THPT chuyên Bến Tre.\n\n" +
        "Lưu ý: câu trả lời của tôi có thể KHÔNG ĐÚNG.\n" +
        "Vui lòng liên hệ trực tiếp nhà trường nếu bạn cần thông tin chính xác nhất.";
      try {
        const res = await checkServer();
        this.online = res.status == 200;
      } catch (error) {
        this.online = false;
        initialMsg = "Xin lỗi, chatbot hiện không sẵn sàng.\n\n" +
          "Vui lòng thử lại sau.";
      }
      this.histories.push({
        system: true,
        msg: initialMsg,
        time: nowTime(),
      });
    }
  }
}
</script>