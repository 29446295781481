<template>
  <div class="chatbox__sender">
    <div class="sender__wrap">
      <div class="sender__input">
        <input v-model="question" @keyup.enter="sendMsg()" type="text" placeholder="Nhập câu hỏi ở đây..." class="message-input" :disabled="!online">
      </div>
      <div class="sender__icon message-submit">
        <i aria-hidden="true" class="fa fa-paper-plane" :class="{'active': question}" @click="sendMsg()"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    online: { type: Boolean, required: true },
  },
  data() {
    return {
      question: ""
    }
  },
  emits: ["submit-question"],
  methods: {
    sendMsg() {
      if (!this.question) return;
      this.$emit("submit-question", this.question);
      this.question = "";
    }
  }
}
</script>